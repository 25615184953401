<template>
  <v-dialog
    v-model="dialog"
    width="500"
    :persistent="nueva"
    scrollable
  >
    <v-card v-if="nueva">
      <v-card-title class="px-4">
        Agregar Series
        <v-col cols="4" sm="3" class="py-0">
          <v-text-field
            v-model="cantidad"
            label="Cantidad"
            type="number"
            hide-details
            outlined
            dense
            @blur="validarCantidad()"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="$emit('cancelar')"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="body-1 pt-0 pb-2 px-4 text--secondary" style="word-break: normal;">
        Artículo: {{ p_articulo.codigo }} - {{ p_articulo.nombre }}
      </v-card-title>
      <v-card-title class="pa-0">
        <v-tabs v-model="tab" :vertical="$vuetify.breakpoint.xs">
          <v-tab>
            Agregar
          </v-tab>
          <v-tab>
            Series disponibles
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card-text class="px-0">
              <v-form @submit.prevent="buscarSerie()">
                <v-text-field
                  v-model.trim="serie"
                  class="mx-4"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-form>
              <v-simple-table
                class="mt-4"
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Series seleccioandas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in selected"
                      :key="index"
                    >
                      <td class="text-center">{{ item.serie }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text class="px-0">
              <v-data-table
                v-model="selected"
                item-key="serie"
                style="cursor: pointer;"
                :items="p_articulo.series_disponibles"
                :headers="headers"
                :search="search"
                :items-per-page="-1"
                hide-default-footer
                show-select
                dense
                @click:row="rowClick"
                @item-selected="validarAlerta"
                @toggle-select-all="validarAlerta"
              >
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6">
                      <search-data-table v-model="search"/>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="350"
                    type="info"
                    border="left"
                    dense
                    text
                  >
                    No hay series disponibles
                  </v-alert>
                </template>
              </v-data-table>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-title class="pa-0" style="word-break: normal;">
        <v-card-text class="pa-0 pt-3">
          <v-expand-transition>
            <div v-if="showAlert">
              <v-alert
                class="mx-8"
                type="error"
                border="left"
                text
              >
                La cantidad de series seleccionadas no coincide con la cantidad ingresada.
              </v-alert>
            </div>
          </v-expand-transition>
        </v-card-text>
      </v-card-title>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          :disabled="cantidad != selected.length"
          @click="$emit('confirmar', selected, cantidad)"
        >
          <v-icon small left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title class="px-4 pb-0" style="word-break: normal">
        Series artículo {{ p_articulo.codigo }} - {{ p_articulo.nombre }}
      </v-card-title>
      <v-card-text class="pa-0">
        <v-simple-table
          class="mt-4"
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Nº Serie</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in p_articulo.series"
                :key="index"
              >
                <td class="body-1 text-center">{{ item.serie }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../../util/SearchDataTable.vue'

export default {
  data () {
    return {
      tab: null,
      serie: '',
      search: '',
      showAlert: false,
      cantidad: 1,
      selected: [],
      headers: [
        { text: 'Nº Serie', value: 'serie', sortable: false }
      ]
    }
  },
  props: {
    value: Boolean,
    nueva: Boolean,
    p_articulo: Object
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable
  },
  watch: {
    dialog (val) {
      if (!this.nueva) return
      if (val) {
        this.tab = this.p_articulo.series_disponibles.length > 10 ? 0 : 1
        this.selected = JSON.parse(JSON.stringify(this.p_articulo.series))
        this.cantidad = JSON.parse(JSON.stringify(this.p_articulo.cantidad))
      } else {
        this.tab = null
        this.serie = ''
        this.search = ''
        this.selected = []
        this.showAlert = false
      }
    }
  },
  methods: {
    validarAlerta (event) {
      let count = 0
      if (Array.isArray(event.items)) {
        count = event.value ? event.items.length : 0
      } else {
        count = this.selected.length + (event.value ? 1 : -1)
      }
      this.showAlert = this.cantidad != count
    },
    validarCantidad () {
      const cantidad = parseInt(this.cantidad)
      if (cantidad <= 0) {
        this.cantidad = JSON.parse(JSON.stringify(this.p_articulo.cantidad))
        this.$store.dispatch('show_snackbar', {
          text: 'La cantidad debe ser mayor o igual a 1',
          color: 'error'
        })
      } else if (cantidad > this.p_articulo.series_disponibles.length) {
        this.cantidad = JSON.parse(JSON.stringify(this.p_articulo.cantidad))
        this.$store.dispatch('show_snackbar', {
          text: 'La cantidad ingresada supera el stock disponible del artículo',
          color: 'error'
        })
      } else {
        this.cantidad = cantidad
      }
      this.showAlert = this.cantidad != this.selected.length
    },
    buscarSerie () {
      if (!this.serie) return
      let existe = this.p_articulo.series_disponibles.find(se => se.serie == this.serie)
      if (existe) {
        let ya_esta = this.selected.find(se => se.serie == this.serie)
        this.serie = ''
        if (ya_esta) {
          this.$store.dispatch('show_snackbar', {
            text: 'La serie ingresada ya se ecuentra en el listado',
            color: 'info'
          })
        } else {
          this.selected.push(existe)
          this.showAlert = this.cantidad != this.selected.length
        }
      } else {
        this.serie = ''
        this.$store.dispatch('show_snackbar', {
          text: 'La serie ingresada no existe para el artículo',
          color: 'error'
        })
      }
    },
    rowClick (item, row) {
      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
    }
  }
}
</script>